@font-face {
  font-family: 'Baloise Create Headline';
  src: url('./BaloiseCreateHeadline-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloise Create Headline';
  src: url('./BaloiseCreateHeadline-RegularIt.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Baloise Create Headline';
  src: url('./BaloiseCreateHeadline-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloise Create Headline';
  src: url('./BaloiseCreateHeadline-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
