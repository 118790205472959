.Copy {
}

.Copy h1 {
  line-height: 1.2;
  font-weight: 700;
  font-size: 32px;
  padding-block-end: 0.5em;
}

.Copy h2,
.Copy h3 {
  line-height: 1.2;
  font-weight: 700;
  font-size: 24px;
  padding-block-end: 0.5em;
}

.Copy h4,
.Copy h5 {
  line-height: 1.2;
  font-weight: 700;
  font-size: 18px;
  padding-block-end: 0.5em;
}

.Copy a {
  text-decoration: underline;
}

.Copy p {
  padding-block-end: 1.5em;
}

.Copy p {
  padding-block-end: 1.5em;
}

.Copy strong {
  font-weight: 700;
}

.Copy em {
  font-style: italic;
}

.Copy ol {
  margin-block: 1em;
  padding-left: 40px;
  list-style-type: decimal;
}

.Copy ul {
  margin-block: 1em;
  padding-left: 40px;
  list-style-type: disc;
}
