html {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-blue: #000d6e;
  --color-grey: #e6e6e6ff;

  --color-purple-100: #6c2273ff;
  --color-purple-80: #9f52ccff;
  --color-purple-60: #be82faff;
  --color-purple-40: #b9b3ffff;
  --color-purple-20: #e1d9ffff;
  --color-red-100: #99172dff;
  --color-red-80: #d9304cff;
  --color-red-60: #ff596fff;
  --color-red-40: #ffaca6ff;
  --color-red-20: #ffd7d7ff;
  --color-green-100: #1b5951ff;
  --color-green-80: #00b38fff;
  --color-green-60: #21d9acff;
  --color-green-40: #94e3d4ff;
  --color-green-20: #cbf2ecff;
  --color-yellow-100: #b34a00ff;
  --color-yellow-80: #fa9319ff;
  --color-yellow-60: #ffbe1aff;
  --color-yellow-40: #fae052ff;
  --color-yellow-20: #ffecbcff;

  --num-columns: 13;
  --cell: calc(100vw / var(--num-columns));
  --header: 0;

  @media (min-width: 1024px) {
    --num-columns: 29;
  }

  font-family: 'Baloise Create Headline', sans-serif;
  color: var(--color-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Main {
  display: block grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-areas:
    'header'
    'main'
    'footer';
  min-height: 100vh;
  margin: 0;
  background-color: var(--color-white);
  font-size: 0.875rem;
  line-height: 1.5;
  overflow-x: hidden;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
