.Main {
  position: relative;
  grid-area: footer;
  padding-inline: calc(var(--cell) * 0.5);
  z-index: 400;
}

.Container {
  display: block grid;
  row-gap: 0.75rem;
  border-block-start: 1px solid var(--color-grey);
  padding-block: 0.75rem 1.5rem;

  @media (min-width: 640px) {
    display: block flex;
    column-gap: 2.5rem;
    padding-block: 1rem;
  }
}

.LinkList {
  display: block grid;
  row-gap: 0.375rem;

  @media (min-width: 1024px) {
    display: block flex;
    column-gap: 1em;
  }
}

.LinkItem {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.2;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  & > a {
    color: currentColor;
    text-decoration: none;
    cursor: pointer;
  }
}

.LanguageList {
  display: block flex;
  column-gap: 0.8em;
}

.LanguageItem {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  &.Active {
    font-weight: 700;
  }

  & > a {
    color: currentColor;
    text-decoration: none;
    cursor: pointer;
  }
}
